/* src/Workflow.css
.workflow {
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
   */

   body {
    font-family: Arial, sans-serif;
    margin: 20px;
    background-color: #f4f4f4;
  }
  
  .workflow {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    font-weight: bold;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
  }
  
  tbody tr:hover {
    background-color: #f1f1f1; /* Highlight on hover */
  }
  
  button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0DAE92; /* Darker green on hover */
    color: white; /* White text on hover */
  }
  .action-button {
    margin-right: 10px; /* Adjust the value as needed */
  }
  .modal {
    position: fixed; /* Use fixed positioning for modals */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex; /* Center the modal content */
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white; /* Modal content background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 90%; /* Responsive width */
    max-width: 90%; /* Set a maximum width for the modal */
    overflow: auto; /* Allow scrolling if content exceeds */
  
  }
  
  .modal table {
    margin: 0; /* Reset margins for the modal table */
    width: '100%';
    border-collapse: collapse; /* Merge borders */
    table-layout: auto; /* Automatically adjust column widths based on content */

  }
  .modal th, .modal td {
    padding: 2px; /* Space inside cells */
    border: 1px solid #ddd; /* Border for cells */
    text-align: left; /* Align text to the left */
    /* word-wrap: break-word; Break long words to fit */
    /* white-space: normal;  Allow wrapping */
  }
  .modal th {
    background-color: #4CAF50; /* Header background */
    color: white; /* Header text color */
  }
  
  .modal tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
  }
  
  .modal tbody tr:hover {
    background-color: #f1f1f1; /* Highlight on hover */
  }
  .modal h2 {
    margin-top: 0; /* Remove margin for heading in modal */
  }
  @media (max-width: 600px) {
    th, td {
      padding: 8px;
      font-size: 14px;
    }
  
    button {
      width: 100%;
    }
  }