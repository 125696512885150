.menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .menu p {
    margin: 0;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .menu p:hover {
    background-color: #00ff9d; /* Change this to your desired hover color */
    color: white; /* Change text color on hover */
  }
  
  .menu button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #f4a836; /* Button color */
    color: white; /* Button text color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menu button:hover {
    background-color: #d32f2f; /* Darker shade on hover */
  }
  