/* Container for the entire login section */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column; /* Stack on smaller screens */
}

/* Image section */
.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.image-section img {
  width: 250px;
  height: auto;
  border-radius: 50%;
  max-width: 100%;
}

/* Form section */
.form-section {
  background-color: rgb(229, 236, 221);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Limit form width */
  box-sizing: border-box;
}

.username-field, .password-field {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Consistent spacing */
}

/* Style for the input fields */
.username-field input, 
.password-field input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fafafa;
  box-sizing: border-box;
}

/* Style for the toggle buttons */
 .toggle-password {
  width: 8%;
  padding: 0%;
  height:auto;
  position: absolute;
  margin-right: 2%;
  margin-top: 0%;
  border-radius: 20%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  outline: none;
}

/* Style for Font Awesome icons */
.toggle-username i, .toggle-password i {
  pointer-events: none; /* Prevent clicks on the icon */
}

/* Fancy button styling */
.fancy-button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #28a745;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fancy-button:hover {
  background-color: #218838;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .image-section img {
    width: 100%;
    max-width: 200px;
  }

  .form-section {
    padding: 20px;
    width: 100%;
    max-width: 300px;
  }

  input, .fancy-button {
    font-size: 16px;
  }
}

/* Password field with icon */
.password-field {
  position: relative;
}

.password-field input {
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

/* General button styling */
button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
