input[type="text"].repay-input,input[type="text"].deposit-input {
    height: 50px;
    max-width: 100%; /* Limit the width of the repay input */
    padding: 1%;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 100%;
    transition: border-color 0.3s;
    width:100%;
    box-sizing: border-box; /* Include padding in width */
    overflow: hidden; /* Prevent overflow */
    padding: 0; /* Reset padding */
    border: 1px solid #ccc; /* Example border */
    outline: none; /* Remove outline on focus */
}

input[type="text"].repay-input:focus {
    border-color: #90EE90; /* Green border on focus */
}
.tab-container {
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: space-between; /* Space between buttons */
    margin: 20px 0; /* Add some margin for spacing */
}

.tab-container button {
    padding: 8px 12px; 
    font-size: 14px; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: background-color 0.3s; /* Smooth background color transition */
}
.tab-container button:hover {
    background-color: #e0e0e0; /* Light gray background on hover */
}
table {
    width: 100%;
    border-collapse: collapse; /* Ensures no space between borders */
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    table-layout: auto; /* Allow columns to adjust to content */
}

th, td {
    padding: 2px; /* Reduce padding to minimize space */
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 1vw;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}




body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9; /* Light background */
    color: #333; /* Dark text for contrast */
    margin: 0;
    padding: 0.1%;
}

h1 {
    color: #90EE90; /* Green for headings */
    text-align: center;
    margin-bottom: 20px;
}


button {
    padding: 10px 20px;
    background-color: #4CAF50; /* Green button */
    color: white;
    border: none;
    width:'5%';
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block; /* Centering button on small screens */
    margin: 10px auto; /* Center button */
}

button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.msg {
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
}


tr:hover {
    background-color: #e8f5e9; /* Light green on row hover */
}


/* Responsive Styles */
@media (max-width: 768px) {
    h1 {
        font-size: 1.5rem; /* Smaller heading on mobile */
    }

    input[type="text"], button {
        width: 80%; /* Full width on smaller screens */
        font-size: 2vw;
    }

    table {
        font-size: 0.1rem; /* Smaller font size for table */
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.25rem; /* Even smaller heading for very small screens */
    }

    th, td {
        padding: 1%; /* Reduced padding for smaller devices */
        font-size: 0.8vw; /* Increase size slightly for small screens */
    
    }

    input[type="text"], button {
        padding: 1px; /* Reduced padding for smaller devices */
        
    }
}

